import { useState } from 'react';
import {
    BaseModal,
    Button,
    Spinner,
    TextField,
} from '../../components/UIElements';
import { Mail } from 'react-feather';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { authenServices } from '~/services';
import './RecoveryPasswordModal.scss';

const initialValues = { email: '' };

const RecoveryPasswordModal = ({ handleClose, intl }) => {
    // to handle server errors
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    const validateSchema = Yup.object().shape({
        email: Yup.string()
            .email(intl.formatMessage({ id: 'email_validate_error' }))
            .required(intl.formatMessage({ id: 'required_field' })),
    });

    const onSubmit = async (values) => {
        setLoading(true);

        const [error] = await authenServices.sendRecoveryEmail({
            email: values.email,
        });

        setLoading(false);

        if (error) {
            handleError(intl.formatMessage({ id: 'general_error' }));
            return;
        }

        setEmailSent(true);
    };

    const formik = useFormik({
        initialValues,
        validationSchema: validateSchema,
        onSubmit,
    });

    const handleError = (erroMsg) => {
        setError(erroMsg);

        setTimeout(() => {
            setError(null);
        }, 5000);
    };

    const { email } = formik.values;

    return (
        <BaseModal size="small" showCloseIcon={true} handleClose={handleClose}>
            <div className="dg-recovery-password-modal">
                <h3> {intl.formatMessage({ id: 'reset_password' })} </h3>

                {!emailSent ? (
                    <form onSubmit={formik.handleSubmit}>
                        <p>
                            {intl.formatMessage({
                                id: 'reset_password_aclaration',
                            })}
                        </p>
                        <TextField
                            iconBefore={Mail}
                            inputProps={{
                                placeholder:
                                    intl.formatMessage({ id: 'email' }) + '...',
                                value: email,
                                name: 'email',
                                onChange: formik.handleChange,
                            }}
                            error={formik.errors.email}
                        />

                        {error && (
                            <span className="dg-recovery-password-modal__error">
                                {error}
                            </span>
                        )}

                        <Button
                            disabled={loading}
                            variant="accent"
                            type="submit"
                        >
                            {loading ? (
                                <Spinner width="15" height="15" weight="2" />
                            ) : (
                                intl.formatMessage({ id: 'send_link' })
                            )}
                        </Button>
                    </form>
                ) : (
                    <div className="animate__animated animate__fadeIn">
                        <p>
                            {intl.formatMessage(
                                {
                                    id: 'check_email_recovery_password',
                                },
                                { email }
                            )}
                        </p>
                    </div>
                )}
            </div>
        </BaseModal>
    );
};

export default RecoveryPasswordModal;
